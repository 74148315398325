/* eslint-disable consistent-return */
import FooterModel from 'Models/Footer/FooterModel.interface';
import { styled } from 'Theme/stitches.config';
import BodyText from 'DesignSystem/Typography/BodyText/BodyText';
import AccordionItem from 'DesignComponents/Molecules/Accordion/AccordionItem';
import LinkList from 'DesignComponents/Organisms/Footer/LinkList/LinkList';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import FeaturedArea from '../FeaturedArea/FeaturedArea';
import KexIconLoader from 'DesignSystem/Icons/KexIconLoader';
import PaymentProvidersIcons from 'Models/Footer/PaymentProvidersIcons.interface';
import useAccordionMode from 'Shared/Hooks/useAccordionMode';

import { useTranslations } from '../../../../context/init-data.context';

declare let Cookiebot: any; //Window.Cookiebot

const KlarnaIcon = KexIconLoader('Klarna');
const SwishIcon = KexIconLoader('Swish');
const VisaIcon = KexIconLoader('Visa');
const MastercardIcon = KexIconLoader('Mastercard');
const MobilePayIcon = KexIconLoader('Mobilepay');

type PropTypes = {
  content: FooterModel;
};

function MobileFooter({
  content: {
    copyright,
    featuredArea,
    footerCenterColumnLinks,
    footerCenterHeader,
    footerLeftColumnLinks,
    footerLeftHeader,
    footerRightColumnLinks,
    footerRightHeader,
    legals,
    paymentProviders,
  },
}: PropTypes) {
  const {
    commonLabels: { cookiePreferences },
  } = useTranslations();
  const footerColumns = [
    {
      header: footerLeftHeader,
      links: footerLeftColumnLinks,
    },
    {
      header: footerCenterHeader,
      links: footerCenterColumnLinks,
    },
    {
      header: footerRightHeader,
      links: footerRightColumnLinks,
    },
  ];

  const [openedIndex, setOpenedIndex, handleToggle] = useAccordionMode();

  const getPaymentIcon = (payment: PaymentProvidersIcons) => {
    switch (payment.name) {
      case 'Klarna':
        return <KlarnaIcon size="l" title={payment.title} key={payment.name} />;
      case 'Swish':
        return <SwishIcon size="l" title={payment.title} key={payment.name} />;
      case 'VISA':
        return <VisaIcon size="l" title={payment.title} key={payment.name} />;
      case 'Mastercard':
        return (
          <MastercardIcon size="l" title={payment.title} key={payment.name} />
        );
      case 'MobilePay':
        return (
          <MobilePayIcon size="l" title={payment.title} key={payment.name} />
        );
      default:
        break;
    }
  };

  return (
    <>
      <Container>
        <ContentContainer>
          <Row>
            <FeaturedArea featuredArea={featuredArea} />
          </Row>
        </ContentContainer>
        <ContentContainer css={{ marginTop: '32px' }}>
          {footerColumns.map((column, index: number) => {
            if (column.header && column.links?.length !== 0) {
              return (
                <AccordionItem
                  key={column.header}
                  header={column.header}
                  isFooter
                  opened={index === openedIndex}
                  onOpening={() => handleToggle(index)}
                  onClosing={() => setOpenedIndex(null)}
                >
                  {column.links && <LinkList links={column.links} />}
                </AccordionItem>
              );
            }
            return null;
          })}
        </ContentContainer>
        <ContentContainer>
          <Row>
            <PaymentIconsWrapper>
              {paymentProviders?.map((payment) => {
                return getPaymentIcon(payment);
              })}
            </PaymentIconsWrapper>
          </Row>
          <Row>
            <CopyrightWrapper>
              <BodyText size="s" color="primary">
                {copyright}
              </BodyText>
            </CopyrightWrapper>
          </Row>
          <Row>
            <LowerTexts>
              {legals?.map((link) => (
                <InteractableContentLink href={link.href} key={link.href}>
                  <BodyText size="s" color="primary">
                    {link.text}
                  </BodyText>
                </InteractableContentLink>
              ))}
              <InteractableContentLink
                href="#"
                onClick={() => {
                  Cookiebot.renew();
                }}
              >
                <BodyText size="s" color="primary">
                  {cookiePreferences}
                </BodyText>
              </InteractableContentLink>
            </LowerTexts>
          </Row>
        </ContentContainer>
      </Container>
    </>
  );
}

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '100%',
  justifyContent: 'center',
});

const Row = styled('div', {
  display: 'flex',
  flexBasis: '100%',
  justifyContent: 'center',
});

const PaymentIconsWrapper = styled('div', {
  mt: '$s200',
  display: 'flex',
  gap: '$s100',
});

const CopyrightWrapper = styled('div', {
  mt: '$s200',
});

const LowerTexts = styled('div', {
  mt: '$s100',
  display: 'flex',
  gap: '$s100',
});

export default MobileFooter;
