import LinkModel from 'Models/Link/LinkModel.interface';
import { styled } from 'Theme/stitches.config';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import BodyText from 'DesignSystem/Typography/BodyText/BodyText';

type PropTypes = {
  links: LinkModel[];
};

function LinkList({ links }: PropTypes) {
  return (
    <>
      <List>
        {links &&
          links.map((item: LinkModel) => (
            <InteractableContentLink href={item.href} key={item.href}>
              <BodyText weight={'regular'} size={'m'}>
                {item.text}
              </BodyText>
            </InteractableContentLink>
          ))}
      </List>
    </>
  );
}
const LinksHover = {
  a: {
    ':hover': {
      textDecoration: 'underline',
      textUnderlineOffset: '3px',
    },
  },
};

const List = styled('ul', {
  listStyle: 'none',
  '& p': {
    color: '$onInverse',
  },
  px: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '$s100',
  ...LinksHover,
});

export default LinkList;
